import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Helpers & mock content
import { fireEvent, goTo, removeTags } from "../../utils/helpers"
import { TextBlockWrapper } from "../../modules/TextBlock/TextBlock"
import { Helmet } from "react-helmet"
import ArrowNegative from "../../components/icons/ArrowNegative"
import { flag } from "country-emoji"
import { Link } from "gatsby"
import PalDropdown from "../../components/PalDropdown"
import usePreviewClient from "../../components/hooks/usePreviewClient"

import COUNTRY_QUERY from "./CountryQuery.gql"
import LEGAL_QUERY from "./PrivacyPagesQuery.gql"
import DownloadPDF from "../../components/icons/DownloadPDF"

export const BlogImage = styled.div`
  max-height: 1173px;
  min-height: 40vw;
  @media (max-width: 640px) {
    height: 280px;
  }
`

// markup
const LegalPage = ({ pageContext }) => {
  const client = usePreviewClient("https://development.web.cms.tier.exu.dev/api")
  const [allPages, setAllPages] = useState()
  const [countries, setCountries] = useState([])
  const [countryPages, setCountryPages] = useState([])
  const { pageInfo, locale, slug } = pageContext
  const { title, richText, termsAndConditionsTags, country, dateUpdated } = pageInfo
  const { documentLanguage, previousVersions } = termsAndConditionsTags[0]
  let metaDescription = richText ? removeTags(richText.split("</")[0]) : ""

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  useEffect(() => {
    if (client) {
      client
        .query({
          query: COUNTRY_QUERY,
        })
        .then(result => {
          console.log("Country Result: ", result.data)
          let countries = []

          result.data.entries.forEach(country => countries.push(country.title))

          setCountries(countries)
          console.log(countries)
        })
        .catch(err => {
          console.log("Error: ", err)
        })

      client
        .query({
          query: LEGAL_QUERY,
        })
        .then(result => {
          setAllPages(result.data.entries)

          let currentPages = result.data.entries.filter(e => e.country[0] && e.country[0].title === country[0].title)
          let sorted = []

          currentPages.forEach(page => sorted.push(page.title))

          console.log("Legal Result: ", sorted)
          setCountryPages(sorted)
        })
        .catch(err => {
          console.log("Error: ", err)
        })
    }
  }, [client])

  const changePage = option => {
    let newPage = allPages.filter(e => e.country[0] && e.title === option && e.country[0].title === country[0].title)[0]

    if (!newPage) return

    goTo("/" + newPage.slug, "internal")
  }

  const changeCountry = option => {
    let newPage = allPages.filter(e => e.country[0] && e.country[0].title === option && e.termsAndConditionsTags[0].isDefault)

    if (!newPage.length) newPage = allPages.filter(e => e.country[0] && e.country[0].title === option)

    if (!newPage[0]) {
      goTo("/privacy-notice", "internal")
      return
    }

    goTo("/" + newPage[0].slug, "internal")
  }

  return (
    <div className="relative">
      <Helmet
        htmlAttributes={{
          locale,
        }}
        title={title}
        titleTemplate={`%s | TIER Mobility`}
      >
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title + " | TIER Blog"} />
        <meta property="og:description" content={metaDescription} />
        {/* <meta property="og:image" content={image[0].url} /> */}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        {title && <meta name="twitter:creator" content={title} />}
        <meta name="twitter:title" content={title + " | TIER Mobility"} />
        <meta name="twitter:description" content={metaDescription} />
        {/* <meta name="twitter:image" content={image[0].url} /> */}
      </Helmet>
      <div className=" pt-12 lg:pt-28 px-5 sm:px-10 md:px-24 flex flex-col lg:flex-row items-start">
        <Link className="w-80 flex space-x-3 cursor-pointer sm:mb-10 mt-12 lg:mt-10 lg:mb-0 lg:sticky top-36" to="/privacy-notice">
          <ArrowNegative className="transform rotate-180 mt-1" />
          <p className="font-bold text-blue">Back to countries</p>
        </Link>
        <div className="w-full relative overflow-hidden">
          {country[0] && (
            <>
              <div className="flex flex-col md:flex-row items-start w-full mb-16 mt-5 space-y-4 md:mb-14 md:my-auto md:space-x-6 md:space-y-0">
                <div className="flex flex-col flex-1 w-full md:w-auto max-w-sm">
                  <input type="hidden" name="country" />
                  <p className="text-n3 m-2">Select country</p>
                  <PalDropdown placeholder="Country" options={countries} defaultValue={country[0].title} onChange={changeCountry} />
                </div>
                <div className="flex flex-col flex-1 w-full md:w-auto max-w-sm">
                  <input type="hidden" name="country" />
                  <p className="text-n3 m-2">Select Page</p>
                  <PalDropdown placeholder="Page" options={countryPages} defaultValue={title} onChange={changePage} />
                </div>
              </div>
              <h3 className="text-blue font-extended">
                <span className="mr-4">{flag(country[0].title)}</span>
                {country[0].title}
              </h3>
            </>
          )}
          {dateUpdated && (
            <p className="font-bold text-blue mt-6 mb-12">Last updated: {new Date(dateUpdated).toLocaleDateString().split("/").join(".")}</p>
          )}
          {/* <p className="mb-5">Last update: 30.05.2022</p> */}
          <h4 className="text-blue font-extended text-h6 lg:text-h4">{title}</h4>
          <TextBlockWrapper
            className="max-w-4xl w-full my-6 sm:my-10 space-y-8 use-hyphens"
            dangerouslySetInnerHTML={{ __html: richText }}
            textTheme="dark"
          />
          {previousVersions && previousVersions.length > 0 && (
            <>
              <h4 className="text-blue font-extended my-14">Download previous versions</h4>
              <div className="w-full max-w-4xl grid grid-cols-3">
                {previousVersions.map(version => {
                  return (
                    <a href={version.url} target="__blank" className="h-20 w-64 bg-blue-background rounded-xl px-4 py-2 text-blue cursor-pointer">
                      <span className="flex space-x-2">
                        <DownloadPDF className="mt-1" />
                        <p className="font-bold">{version.title}</p>
                      </span>
                      {/* <p className="text-n3">{version.title.split("-")?.shift()?.join() || ""}</p> */}
                    </a>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

LegalPage.propTypes = {
  pageContext: PropTypes.shape({
    blogInfo: PropTypes.object,
    footerInfo: PropTypes.object,
    locale: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default LegalPage
